@import url('https://fonts.googleapis.com/css?family=Roboto');
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.header img {
  max-width: 100%;
  max-height: 50vh;
  object-fit: contain;
  margin-top: 12.5vh;
}

.header p {
  font-family: Roboto, sans-serif;
  color: white;
  display: block;

}

.header {
  width: auto;
  height: 100vh;
  background-color: #5b7f95;
  padding: 0 10vw;
}

.header .title {
  font-weight: bold;
  color: white;
  margin-top: 2%;
}

@media screen and (min-width: 320px) {
  .header .title {
    font-size: calc(30px + 6 * ((100vw - 320px) / 680));
  }
  .header p{
    font-size: calc(20px + 6 * ((100vw - 320px) / 680));
    margin-top: calc(20px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  .header .title {
    font-size: 40px;
  }

  .header p{
    font-size: 20px;
    margin-top: 20px;
  }
}

.option {
  font-family: Roboto, sans-serif;
  color: #4c4c4c !important;
  background-color: #b2c6d1 !important;
  border: 1px solid #5b7f95;
  border-radius: 0;
  text-align: center;
  padding: 2% 2%;
}

@media screen and (min-width: 320px) {
  .option{
    font-size: calc(15px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  .option{
    font-size: 20px
  }
}

.option:hover {
  background-color: #d5e4ed !important;
  color: initial;
  border-color: initial;
  cursor: pointer;
}

.option:active {
  background-color: #d5e4ed;
}

.option.chosen-one {
  background-color: #d5e4ed !important;
}

.option-group .option{
  margin: 2.5%;
}

.courses .btn {
  border-color: #5b7f95;
  font-family: Roboto, sans-serif;
  color: #5b7f95;
  font-weight: bold;
  background-color: white;
}

.courses p.course {
  display: inline-block;
  margin-top: 10px;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  color: #4c4c4c;
  text-align: center;
}

@media screen and (min-width: 320px) {
  .courses p.course {
    font-size: calc(15px + 6 * ((100vw - 320px) / 680));
  }

  .courses .hero img {
    width: 80%;
  }
}
@media screen and (min-width: 1000px) {
  .courses p.course {
    font-size: 20px;
  }

  .courses .hero img {
    width: 100%;
  }
}

.courses .hero {
  position: relative;
  min-width: 200px;
  background-color: #b2c6d1;
  padding-top: 5%;
  border: 1px solid #5b7f95;
  padding-bottom: 2%;
  cursor: pointer;
  margin: 2%;
}

.courses .hero:hover {
  background-color: #d5e4ed;
  color: initial;
  border-color: initial;
}

.courses .hero:active {
  background-color: #d5e4ed;
  color: initial;
  border-color: initial;
}

.courses .hero.chosen-one {
  background-color: #d5e4ed;
}

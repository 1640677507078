@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }


.header img {
  max-width: 100%;
  max-height: 50vh;
  object-fit: contain;
  margin-top: 12.5vh; }

.header p {
  font-family: Roboto, sans-serif;
  color: white;
  display: block; }

.header {
  width: auto;
  height: 100vh;
  background-color: #5b7f95;
  padding: 0 10vw; }

.header .title {
  font-weight: bold;
  color: white;
  margin-top: 2%; }

@media screen and (min-width: 320px) {
  .header .title {
    font-size: calc(30px + 6 * ((100vw - 320px) / 680)); }
  .header p {
    font-size: calc(20px + 6 * ((100vw - 320px) / 680));
    margin-top: calc(20px + 6 * ((100vw - 320px) / 680)); } }

@media screen and (min-width: 1000px) {
  .header .title {
    font-size: 40px; }
  .header p {
    font-size: 20px;
    margin-top: 20px; } }

.mainform p.titleContainer {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  color: #5b7f95;
  padding-top: 4vh; }

@media screen and (min-width: 320px) {
  .mainform p.titleContainer {
    font-size: calc(30px + 6 * ((100vw - 320px) / 680)); } }

@media screen and (min-width: 1000px) {
  .mainform p.titleContainer {
    font-size: 30px; } }

.mainform .inputLabel {
  font-family: Roboto, sans-serif;
  color: #5b7f95;
  font-size: 22px; }

.mainform .checkbox {
  font-family: Roboto, sans-serif;
  color: #5b7f95;
  font-size: 17px;
  margin-left: 15px;
  margin-top: 5px; }

.mainform .checkbox a {
  color: #5b7f95;
  font-weight: bold; }

.mainform.container {
  width: 90%; }

.mainform #name,
.mainform #email {
  height: 70px; }

.mainform ::-webkit-input-placeholder {
  font-size: 20px !important; }

.mainform :-moz-placeholder {
  /* Firefox 18- */
  font-size: 20px !important; }

.mainform ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 20px !important; }

.mainform input[type='checkbox'] {
  width: 30px;
  height: 30px;
  margin-right: 30px !important; }

.mainform .submit {
  margin: 7vw; }

.mainform .error {
  color: red;
  font-weight: bold;
  font-size: 90%; }

.option {
  font-family: Roboto, sans-serif;
  color: #4c4c4c !important;
  background-color: #b2c6d1 !important;
  border: 1px solid #5b7f95;
  border-radius: 0;
  text-align: center;
  padding: 2% 2%; }

@media screen and (min-width: 320px) {
  .option {
    font-size: calc(15px + 6 * ((100vw - 320px) / 680)); } }

@media screen and (min-width: 1000px) {
  .option {
    font-size: 20px; } }

.option:hover {
  background-color: #d5e4ed !important;
  color: #000;
  color: initial;
  border-color: currentColor;
  border-color: initial;
  cursor: pointer; }

.option:active {
  background-color: #d5e4ed; }

.option.chosen-one {
  background-color: #d5e4ed !important; }

.option-group .option {
  margin: 2.5%; }

.courses .btn {
  border-color: #5b7f95;
  font-family: Roboto, sans-serif;
  color: #5b7f95;
  font-weight: bold;
  background-color: white;
}

.courses p.course {
  display: inline-block;
  margin-top: 10px;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  color: #4c4c4c;
  text-align: center;
}

@media screen and (min-width: 320px) {
  .courses p.course {
    font-size: calc(15px + 6 * ((100vw - 320px) / 680));
  }

  .courses .hero img {
    width: 80%;
  }
}
@media screen and (min-width: 1000px) {
  .courses p.course {
    font-size: 20px;
  }

  .courses .hero img {
    width: 100%;
  }
}

.courses .hero {
  position: relative;
  min-width: 200px;
  background-color: #b2c6d1;
  padding-top: 5%;
  border: 1px solid #5b7f95;
  padding-bottom: 2%;
  cursor: pointer;
  margin: 2%;
}

.courses .hero:hover {
  background-color: #d5e4ed;
  color: #000;
  color: initial;
  border-color: currentColor;
  border-color: initial;
}

.courses .hero:active {
  background-color: #d5e4ed;
  color: #000;
  color: initial;
  border-color: currentColor;
  border-color: initial;
}

.courses .hero.chosen-one {
  background-color: #d5e4ed;
}

.definicoes h1,
.definicoes h2,
.definicoes h3,
.definicoes h4 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  color: #5b7f95;
  margin-top: 5vh;
}

.definicoes .extrair-dados-button {
  margin-left: 10px;
  margin-bottom: 10px;
}

.definicoes input[type='date'] {
  margin-left: 10px;
  margin-right: 10px;
}

.definicoes .nav-link:not(.active) {
  cursor: pointer;
}

.definicoes .manage-panel {
  padding-top: 20px;
}


.definicoes h1,
.definicoes h2,
.definicoes h3,
.definicoes h4 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  color: #5b7f95;
  margin-top: 5vh;
}

.definicoes .extrair-dados-button {
  margin-left: 10px;
  margin-bottom: 10px;
}

.definicoes input[type='date'] {
  margin-left: 10px;
  margin-right: 10px;
}

.definicoes .nav-link:not(.active) {
  cursor: pointer;
}

.definicoes .manage-panel {
  padding-top: 20px;
}

.mainform p.titleContainer {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  color: #5b7f95;
  padding-top: 4vh;
}
@media screen and (min-width: 320px) {
  .mainform p.titleContainer {
    font-size: calc(30px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  .mainform p.titleContainer {
    font-size: 30px
  }
}

.mainform .inputLabel {
  font-family: Roboto, sans-serif;
  color: #5b7f95;
  font-size: 22px;
}

.mainform .checkbox {
  font-family: Roboto, sans-serif;
  color: #5b7f95;
  font-size: 17px;
  margin-left: 15px;
  margin-top: 5px;
}

.mainform .checkbox a {
  color: #5b7f95;
  font-weight: bold;
}

.mainform.container {
  width: 90%;
}

.mainform #name,
.mainform #email {
  height: 70px;
}

.mainform ::-webkit-input-placeholder {
  font-size: 20px !important;
}

.mainform :-moz-placeholder {
  /* Firefox 18- */
  font-size: 20px !important;
}
.mainform ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 20px !important;
}

.mainform input[type='checkbox'] {
  width: 30px;
  height: 30px;
  margin-right: 30px !important;
}

.mainform .submit {
  margin: 7vw;
}

.mainform .error {
  color: red;
  font-weight: bold;
  font-size: 90%;
}
